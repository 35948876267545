import { render, staticRenderFns } from "./creationCard.vue?vue&type=template&id=76350166&scoped=true&"
import script from "./creationCard.vue?vue&type=script&lang=ts&"
export * from "./creationCard.vue?vue&type=script&lang=ts&"
import style0 from "./creationCard.vue?vue&type=style&index=0&id=76350166&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76350166",
  null
  
)

export default component.exports