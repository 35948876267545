
import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackBar from '@/components/BackBar.vue';
@Component({
    components: {
        BackBar,
    },
})
export default class Reg extends Vue {
    @Prop()
    private src!: string;

    @Prop()
    private checked!: string;

    codeSended = false;
    interval = 0;

    ruleForm = {
        email: '',
        code: '',
        pwd: '',
        pwd1: '',
    };
    rules = {
        email: [
            {
                required: true,
                message: window.L('请输入正确的邮箱地址'),
                trigger: 'blur',
                pattern: /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/,
            },
        ],
        code: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
        pwd: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
        pwd1: [{ required: true, message: window.L('请输入'), trigger: 'blur' }],
    };

    targetInterval = 0;

    async fetchSysAvatarList() {
        const resp = await axios.get('/api/misc/get?tpl=avatar');
        const list = JSON.parse(resp.data.data.text);
        return list;
    }

    // async mounted() {}
    checkEmailExist() {
        if (!this.ruleForm.email) {
            return;
        }
        return axios
            .post('/reg/reg-email-check-exist', {
                email: this.ruleForm.email,
            })
            .then(data => {
                return data.data.code === 0;
            });
    }
    async sendCode() {
        const match = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/gi;
        if (!this.ruleForm.email || !match.test(this.ruleForm.email)) {
            this.$message({
                message: window.L('请输入正确的邮箱地址'),
                type: 'error',
                showClose: true,
                offset: 0,
                duration: 1600,
            });
            return;
        }
        const emailAvalid = await this.checkEmailExist();
        if (!emailAvalid) {
            this.$message({
                message: 'email exist',
                type: 'error',
                showClose: true,
                offset: 0,
                duration: 1600,
            });
            return;
        }

        axios
            .post('/api/login/send-reg-mail', {
                email: this.ruleForm.email,
            })
            .then(data => {
                console.log(`data`, data);
                this.$message({
                    message: data.data.msg,
                    type: data.data.code === 0 ? 'success' : 'error',
                    showClose: true,
                    offset: 0,
                    duration: 1600,
                });
                if (data.data.code === 0) {
                    this.codeSended = true;
                    this.interval = 60;
                    this.targetInterval = setInterval(() => {
                        this.interval--;
                        if (this.interval <= 0) {
                            this.interval = 0;
                            clearInterval(this.targetInterval);
                        }
                    }, 1000);
                }
            });
    }

    L(key: string) {
        return window.L(key);
    }
    submit() {
        if (this.ruleForm.pwd !== this.ruleForm.pwd1) {
            this.$message.error(this.L('两次输入的密码不一致'));
            return;
        }
        (this.$refs as any).ruleForm.validate((result: any) => {
            if (result) {
                axios
                    .post('/api/login/bind-email', {
                        pwd: this.ruleForm.pwd,
                        code: this.ruleForm.code,
                    })
                    .then(data => {
                        console.log(`data2`, data);
                        this.$message({
                            message: data.data.msg,
                            type: data.data.code === 0 ? 'success' : 'error',
                            showClose: true,
                            offset: 0,
                            duration: 1600,
                        });
                        if (data.data.code === 0) {
                            this.$router.replace('/my');
                        }
                    });
            }
        });
    }
}
